<template>
  <div class="page-chien">
    <div class="container">
      <fil-ariane :navigation="navigation"></fil-ariane>
      <ul v-if='getErrors' class='error-messages'>
        <li v-for='(v, k) in getErrors' :key='k'>{{ v | error }}</li>
      </ul>
      <h1>
        {{ getPrevDog() }}
        <button class="goto-previous"  v-if="getPrevDog(id)" @click="onGotoPrevDog(id)">
          <PreviousLogo class="previous"></PreviousLogo></button>
        {{ this.getRace }}
        <button class="goto-next"  v-if="getNextDog(id)" @click="onGotoNextDog(id)">
          <NextLogo class="next" />
        </button>
        <br />
        <span :class="`clazz ${getClassSex}`">{{ this.getClassLabel }}
        {{  this.dog.nr_sexe === '1' ? $t('global.male') : $t('global.female')  }}
          </span>
      </h1>

      <div class="flex-header">
        <div class="info">
          <h2>
            N° {{this.dog.nr_cat}} - {{this.dog.identifiant}}
          </h2>
          <h3 v-if="this.dog.nr_sexe === '1'">
            {{$t('dog.his_birthday', { date: getFormatedBirthday() }) }} -
            {{$t('dog.age', {
            nbYears: (Math.floor(getAgeByMonth()/12)),
            nbMonths: (Math.floor(getAgeByMonth() % 12)),
          })}}
          </h3>
        </div>
        <div class="shortcuts">
          <div class="actions">
            <button class="action cancel" @click="onReset" :disabled="getRewards.length > 0">
              {{ $t('global.reset') }}</button>
            <button class="action submit" :disabled="!canSubmit" @click="onSubmit" >
              {{ $t('global.save') }}</button>
          </div>
        </div>
      </div>

      <div class="message information" v-if="isLocked">
        {{ $t('dog.no_changes_allowed')}}
      </div>
      <ul v-if="getRewards" class="rewards">
        <li v-for="reward of getRewards" :key="reward"
          class="reward" v-bind:class="reward">
          <RewardLogo v-if="! ['RCACS', 'CACS', 'RCACIB', 'CACIB'].includes(reward)">
            <title v-html="reward"></title>
          </RewardLogo>
          <span v-html="reward"></span>
        </li>
      </ul>

      <h3 v-if="this.dog.nr_sexe === '2'">
        {{$t('dog.her_birthday', { date: getFormatedBirthday() }) }} -
        {{$t('dog.age', {
        nbYears: (Math.floor(getAgeByMonth() / 12)),
        nbMonths: (Math.floor(getAgeByMonth() % 12)),
        })}}
        <span v-if="this.dog.a_confirmer === '1'" class="to_be_confirmed">
          {{ $t('dog.to_be_confirmed')}}
        </span>
      </h3>
      <textarea v-model="comment" :placeholder="$t('dog.comment')" :disabled="isLocked"></textarea>

      <grille-qualification :classe_id="dog.classe_id" :qualifier_id="'' + qualifier"
        v-on:change-qualifier="onChangeQualifier" :disabled="this.isLocked" />
      <div class="actions">
        <button class="action back" @click="onGoBack">{{ $t('global.back') }}</button>
        <button class="action cancel" @click="onReset" :disabled="this.isLocked">
          {{ $t('global.reset') }}</button>
        <button class="action submit" :disabled="!canSubmit"
          @click="onSubmit">{{ $t('global.save') }}</button>
        <div class="break"></div>
        <button class="action submit-leave" :disabled="!canSubmit"
          @click="onSubmitAndLeave">{{ $t('global.submit_and_leave') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import GrilleQualification from '@/components/GrilleQualification.vue';
import FilAriane from '@/components/FilAriane.vue';

import { EVALUATE_DOG } from '@/store/const/actions.type';

import dogMixin from '../common/dog.mixin';
import errorMixin from '../common/errors.mixin';
import PreviousLogo from '../assets/previous.svg';
import NextLogo from '../assets/next.svg';
import RewardLogo from '../assets/reward.svg';

export default {
  name: 'chien',
  mixins: [dogMixin, errorMixin],
  components: {
    FilAriane,
    GrilleQualification,
    PreviousLogo,
    NextLogo,
    RewardLogo,
  },
  computed: {
    ...mapGetters([
      'getErrors',
      'getDogById',
      'getNextDog',
      'getPrevDog',
      'getRaceById',
      'getClassById',
      'currentUser',
      'currentLang',
      'getDogResult',
    ]),
    getRace() {
      if (!this.dog) {
        return '';
      }
      return this.getRaceLabel(this.getRaceById(this.dog.race_id));
    },
    getClassLabel() {
      const dogClass = this.getClassById(this.dog.classe_id);
      return this.getDogClassLabel(dogClass);
    },
    getClassSex() {
      return this.dog.nr_sexe === '1' ? 'male' : 'female';
    },
    canSubmit() {
      return true; // this.isFilled;
    },
    isFilled() {
      return this.qualifier;
    },
    getRewards() {
      if (!this.dog.recompenses) {
        return [];
      }
      return this.dog.recompenses.split(',')
        .map((reward) => this.$i18n.t(`rewards.${reward}`));
    },
    navigation() {
      return [
        { name: this.$i18n.t('breadcrumb.shows', { name: this.currentUser.nom }), link: '/expos' },
        {
          name: this.getRace,
          link: `/race/${this.dog.race_id}`,
        },
        { name: ` N° ${this.dog.nr_cat}`, link: '' },
      ];
    },
    dog() {
      const found = this.getDogById(this.id);
      if (!found) {
        // console.error('ooook', this.$toasted);
        this.$store.dispatch('error', this.$i18n.t('dog.invalid'));
        this.$router.replace({ name: 'home' });
      }
      return found;
    },
    isLocked() {
      return this.getRewards.length > 0;
    },
  },
  mounted() {
    this.refresh();
    window.scrollTo(0, 0);
  },
  props: {
    id: { type: String },
  },
  data() {
    return {
      qualifier: { type: String },
      comment: { type: String },
      classement: { type: String },
    };
  },
  methods: {
    refresh() {
      this.comment = '';
      this.qualifier = '';
      const results = this.getDogResult(this.id);
      if (results) {
        this.comment = results.comment;
        this.qualifier = results.qualifier;
        this.classement = results.classement;
      }
    },
    getClassMessage(clz) {
      // return clz;
      console.log(clz);
      return '';
    },
    hasResult(dog) {
      // console.log(this.getDogResult(dog.id));
      return this.getDogResult(dog.id);
    },
    onChangeQualifier(evt) {
      // console.log('onChangeQualifier', evt);
      this.qualifier = evt.value;
      // console.log(this);
    },
    getFormatedBirthday() {
      if (this.dog.date_naissance === '') {
        return '';
      }
      const date = this.getDateFR(this.dog.date_naissance);
      if (!date) {
        return '';
      }
      let localeStr = 'en-US';
      if (this.currentLang === 'fr') {
        localeStr = 'fr-FR';
      }

      return date.toLocaleDateString(
        [localeStr],
        {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        },
      );
    },
    getAgeByMonth() {
      const date = this.getDateFR(this.dog.date_naissance);

      if (!date) {
        return 0;
      }
      const today = new Date();
      const res = today.getMonth() - date.getMonth()
        + (12 * (today.getFullYear() - date.getFullYear()));
      // console.log(date, res);
      return res;
    },
    onReset() {
      this.$store.dispatch(EVALUATE_DOG, {
        dog: this.dog,
        comment: '',
        qualifier: '',
        classement: 0,
      }).then(() => {
        this.comment = '';
        this.qualifier = null;
      }).catch((err) => this.handleError(err));
    },
    onGoBack() {
      this.$router.push({ name: 'race', params: { id: this.dog.race_id, dog_id: this.dog.nr_cat } });
    },
    onGotoPrevDog(id) {
      const dogId = this.getPrevDog(id).nr_cat;
      this.$store.dispatch(EVALUATE_DOG, {
        dog: this.dog,
        comment: this.comment,
        qualifier: this.qualifier,
        classement: this.classement,
      }).then().catch((err) => this.handleError(err))
        .finally(() => {
          this.$router.push({ name: 'chien', params: { id: dogId } });
        });
    },
    onGotoNextDog(id) {
      const dogId = this.getNextDog(id).nr_cat;
      this.$store.dispatch(EVALUATE_DOG, {
        dog: this.dog,
        comment: this.comment,
        qualifier: this.qualifier,
        classement: this.classement,
      }).then().catch((err) => this.handleError(err))
        .finally(() => {
          this.$router.push({ name: 'chien', params: { id: dogId } });
        });
    },
    onSubmit() {
      this.$store.dispatch(EVALUATE_DOG, {
        dog: this.dog,
        comment: this.comment,
        qualifier: this.qualifier,
        classement: this.classement,
      }).then(() => {
        console.log('save (and stay)', { name: 'race', params: { id: this.dog.race_id } });
      }).catch((err) => this.handleError(err));
    },
    onSubmitAndLeave() {
      this.$store.dispatch(EVALUATE_DOG, {
        dog: this.dog,
        comment: this.comment,
        qualifier: this.qualifier,
        classement: this.classement,
      }).then(() => {
        console.log('save (and leave)', { name: 'race', params: { id: this.dog.race_id } });
      }).catch((err) => this.handleError(err))
        .finally(() => {
          this.$router.push({ name: 'race', params: { id: this.dog.race_id, dog_id: this.dog.nr_cat } });
        });
    },
  },
  watch: {
    '$route.params': 'refresh',
  },
};
</script>

<style lang="scss">
  .page-chien {
    .clazz {
      &.male {color: #A0CFD6}
      &.female {color: #E6A9A9}
    }
    textarea { width: 100%; height: 10rem; resize: none;  box-sizing: border-box;}
    .rewards {
      display: flex; gap:2rem; justify-content: start; margin-bottom:1rem;
      > * {padding:.5rem 0; background: white; font-size: 1.2rem; font-weight: bold }
      .reward { background-color:white; background-position: 0.2rem center;
        background-size: 1.5rem; background-repeat: no-repeat;
        &.RCACS, &.CACS { background-image: url('../assets/CACS.png'); padding-left: 2rem }
        &.RCACIB, &.CACIB { background-image: url('../assets/CACIB.png'); padding-left: 2rem }
      }
      svg, span {height: 2rem; line-height: 2rem; vertical-align: middle}
    }
    .to_be_confirmed {display: inline-block; float: right}

    .actions {
      margin-top:1rem; display: flex; justify-content: flex-end; flex-wrap: wrap; gap:.2rem;
      .cancel {margin-right:2rem;}
      .back {margin-right: auto}
      .action:disabled {opacity: .6 }
      .break {flex-basis: 100%; height: 0}
      .submit-leave {
        margin-top: 1rem; background-color: #225d15;
        &:hover {background-color: #268012}
      }
    }
    .flex-header {
      display: flex;
      .info {flex: 1}
      .inline {flex:0; flex-basis: 300px}
    }
    .goto-previous { float:left; font-size: 1rem;font-weight:bolder !important;
      border:0; background-color: white; padding: .2rem; user-select: none}
    .previous, .next {width:1.2rem}
    .goto-next {float:right; font-size: 1rem;font-weight:bolder !important;
      border:0; background-color: white; padding: .2rem; user-select: none}
  }
</style>
