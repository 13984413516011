import { PermissionError, ServerError, NetworkError } from '@/store/const/exceptions';
// import { ERROR, HAS_OFFLINE_QUERY, INFO } from '@/store/const/actions.type';
import { ERROR, HAS_OFFLINE_QUERY } from '@/store/const/actions.type';

export default {
  methods: {
    handleError(error) {
      // console.error(error);
      if (error instanceof PermissionError) {
        return this.$store.dispatch(ERROR, this.$i18n.t(`error.${error.translatedMessage}`));
      }
      if (error instanceof ServerError) {
        return this.$store.dispatch(ERROR, this.$i18n.t(`error.${error.translatedMessage}`));
      }

      if (error instanceof NetworkError) {
        // eslint-disable-next-line consistent-return
        return this.$store.dispatch(HAS_OFFLINE_QUERY, true);
        // .then(() =>
        // this.$store.dispatch(INFO, this.$i18n.t(`error.${error.translatedMessage}`)));
      }

      return this.$store.dispatch(ERROR, error);
    },
  },
};
