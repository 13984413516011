<template>
  <div class="grille-qualification">
    <div
      class="entry "
      v-bind:class="{ disabled: disabled, 'active': qualifier_id === qualifier.id }"
      v-for="qualifier of listQualifiers"
      :value="qualifier.id"
      :key="qualifier.id"
      @click="onChangeValue(qualifier.id)"
    >
      {{ translate(qualifier) }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'grille-qualification',
  computed: {
    ...mapGetters([
      'getDogResult',
      'currentLang',
      'getQualifiersByClassId',
    ]),
    listQualifiers() {
      const qualifiers = this.getQualifiersByClassId(this.classe_id);
      // todo HACK
      // const missing = qualifiers.find((q) => q.lib_fr === 'ABSENT');
      // if (missing) {
      //   missing.id = 100; // forced different ID
      // }
      const notEvaluated = qualifiers.find((q) => q.lib_fr === 'NE PEUT ETRE JUGE');
      if (notEvaluated) {
        notEvaluated.id = 500; // forced different ID
      }
      // console.log(qualifiers);
      return qualifiers;
    },

  },
  props: {
    classe_id: { type: String },
    qualifier_id: { type: String, default: '' },
    disabled: { type: Boolean },
  },
  methods: {
    translate(qualifier) {
      if (!qualifier) {
        return '';
      }
      if (this.currentLang === 'fr') {
        return qualifier.lib_fr;
      }
      if (this.currentLang === 'en' && qualifier.lib_en !== '') {
        return qualifier.lib_en;
      }
      // default
      console.warn('language/user is invalid', this.currentLang, qualifier);
      return qualifier.lib_fr;
    },
    onChangeValue(val) {
      if (this.disabled) {
        return;
      }
      this.$emit('change-qualifier', { value: val });
    },
  },
};
</script>

<style lang="scss">
  .grille-qualification {
    display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: min(2rem, 4vw) min(2rem, 4vw);
    padding: 1rem 0;
    .entry {
      background-color: #f3e6d9; padding: 1.5rem 1rem; border:1px #CCC solid; text-align: center;
      font-weight: 500;
      &.disabled { opacity: .6 }
      &.active {background: #b9aea3}
      &:not(.disabled):hover{ cursor: pointer; background-color: #f5e8b0; }
    }
  }
</style>
