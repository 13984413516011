<template>
  <div class="fil-ariane">
    <ul v-if="navigation && navigation.length">
      <li
        v-for="(nav, i) in navigation"
        :key="`nav${i}`">
        <router-link v-if="nav.link !== ''" :to="nav.link">{{ nav.name }}</router-link>
        <span v-if="nav.link === ''">{{ nav.name }}</span>
        </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable object-shorthand */

export default {
  name: 'fil-ariane',
  props: {
    navigation: { type: Array },
  },
  methods: {
    onChangeNav(nav) {
      this.$router.push(nav.link);
    },
    getTranslatedNav(nav) {
      return nav.name;
    },
  },
};
</script>

<style lang="scss">
  .fil-ariane {
    ul {
      li {
        display: inline-block; padding:.5rem .5rem .5rem 0;
        &:after { content: '> '; padding: 0 1rem }
        &:last-of-type:after { content: ''; }
      }
    }

  }
</style>
